import React from 'react';

import {withRouter} from 'react-router';
import gql from "graphql-tag";
import BasicQueryComponent from '../BasicQueryComponent';
import {Query} from 'react-apollo';
import Loading from "../Loading";

class QueryWrapper extends BasicQueryComponent {

    constructor(props) {
        super(props);
    }

    render() {
        console.log("TEST");
        function recursiveMap(children, extraProps) {
            return React.Children.map(children, child => {
                if (!React.isValidElement(child)) {
                    return child;
                }

                if (child.props.children) {
                    child = React.cloneElement(child, {
                        children: recursiveMap(child.props.children, extraProps),
                        ...extraProps
                    });
                }

                return React.cloneElement(child, extraProps);
            });
        }

        return (
            <Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={this.transformVariables()}>
                {(result) => {
                    if (result.loading) return <Loading/>;
                    if (result.error) return <div>{result.error} </div>;
                    const dataWithParents = this.setParents(result.data, []);
                    const data = this.transformData(dataWithParents, this.props.expression);

                    const children = recursiveMap(this.props.children, {data: data});

                    return (
                        <div>
                            {children}
                        </div>
                    )
                }}
            </Query>
        );
    }
}

export default withRouter(QueryWrapper);
