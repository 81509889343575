import React, {Component, resetGlobal} from 'reactn';
import {Redirect} from 'react-router-dom';
import {withApollo} from 'react-apollo';

class Logout extends Component {
    componentWillMount() {
        document.cookie = "token=false;expires="+(new Date()).toUTCString()+";path=/";
        delete localStorage.token;
        resetGlobal();
        this.props.client.resetStore();
    }

    render() {
        return (<Redirect to={{
            pathname: '/login',
        }}/>);
    }
}

export default withApollo(Logout);
