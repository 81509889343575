import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "../Login/i18n";
import { compose } from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "../../../utils/Client";
import ReactDOM from "react-dom";
import ExpiringAlert from "../../../components/ExpiringAlert";

const validate = values => {
    const errors = {}
    const requiredFields = ['username', 'password']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    })
    if (values.username && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
}


class ForgottenPassword extends Component {
    constructor(props){
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    errorHandler(error) {
        console.log('there was an error sending the query', error);
        throw new SubmissionError({ _error: i18n.t('login.errorAuth') }); }

    handleFormSubmit(values, event, props) {
        return axios.get(`${CORE_URL}/reset-password?username=${values.username}&url=${btoa(window.location.origin+'/#/reset-password/')}&locale=${navigator.language}`)
        .then((response) => {
            ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('resetPassword.emailSent')} />, document.getElementById('alert'));
        })
        .catch(this.errorHandler.bind(this));

    }

    render() {

        const {handleSubmit, onSubmit, pristine, reset, invalid, submitting, error} = this.props;

        const errorMessage = error ? <p className="text-danger">{ error }</p> :
            <p className="signin-text-muted">{ i18n.t('forgottenPassword.explanation') }</p>
        return (
            <div className="app flex-row align-items-center">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col md="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <CardGroup>
                                <Card className="p-4 login-panel">
                                    <CardBody className='credentials-card'>
                                        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                            <div className='login-logo'></div>
                                            {errorMessage}
                                            <label htmlFor="username"
                                                   className='label-name'>{ i18n.t('login.userEmail') }</label>
                                            <Field inputGroup={{className: "mb-3"}}
                                                   placeholder={ i18n.t('login.userEmail') }
                                                   //inputAddonGroup={{className: 'icon-user'}}
                                                   name="username"
                                                   component={renderInput} type="email"/>

                                            {/*<Row className='margin-b-30'>*/}
                                                {/*<Col>*/}
                                                    {/*<Field name="autologin"*/}
                                                           {/*id="autologin"*/}
                                                           {/*component="input"*/}
                                                           {/*className='css-checkbox'*/}
                                                           {/*type="checkbox"/>*/}

                                                    {/*<label htmlFor="autologin"*/}
                                                           {/*className="css-label"><i className="icon ion-android-checkbox"></i>{ i18n.t('login.autoLogin') }</label>*/}
                                                {/*</Col>*/}
                                                {/*<Col xs="6" className="text-right">*/}
                                                    {/*<Button color="link"*/}
                                                            {/*className="px-0">{ i18n.t('login.forgottenPasswordLink') }</Button>*/}
                                                {/*</Col>*/}
                                            {/*</Row>*/}
                                            <Row>
                                                <Col xs="6">
                                                    <Button color="primary"
                                                            className="px-4 sign-in"
                                                            disabled={invalid || submitting}>{ i18n.t('resetPassword.buttonLabel') }</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="text-center side-image">
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(ForgottenPassword);
