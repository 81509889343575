
import moment from "moment";

const schema = {
    type: 'object',
    properties: {
        id: {
            type: 'string',
            title: 'Id'
        },
        name: {
            type: 'string',
            title: ' ',
            placeholder: 'Task Name'
        },
        description: {
            type: 'string',
            title: 'Description',
            placeholder: 'Task Description'
        },
        process: {
            type: 'string',
            title: 'Process',
            'default': '',
            'enum': [
                'Complains',
                'Block meter from billing',
                'Change tariff',
                'Contract termination',
                'Meter measurement',
                'Reading estimation',
                'Live Billing',
                'Special needs gas and electricity',
                'Change meter technical details',
                'Change of ownership',
                ...DYCE ? ['Sales',
                    'Meter Read',
                    'Meter Query',
                    'Bill Query',
                    'Commission Query',
                    'Contract Query',
                    'Change of Tenancy',
                    'General Query'] : []
            ]
        },
        assignee: {
            type: 'string',
            title: 'Assignee'
        },
        customer: {
            type: 'string',
            title: 'Customer'
        },
        dueDate: {
            type: 'string',
            title: 'Due Date',
            default:""
        },
        status: {
            type: 'string',
            title: 'Status',
            'default': 'Open',
            'enum': [
                'Open',
                'In Progress',
                'Closed',
                'Cancelled'
            ]
        },
        priority: {
            type: 'integer',
            title: 'Priority',
            'default': 0,
            'enum': [
                0,
                50,
                100
            ],
            enumNames: [
                'Low',
                'Medium',
                'High'
            ]
        }
    }
};

export default schema;