import React, { Component } from 'react';
import axios from "../../utils/Client";
import moment from "moment";
import { Button, Breadcrumb, Col, Row, FormGroup, Input } from "reactstrap";
import fileDownload from "js-file-download";


class Transports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file:null
        };
    }

    render() {
        return (
            <div>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Transports</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                <Button onClick={()=> {
                    axios.get(`${CORE_URL}/transport`).then((response) => {
                        const date = new Date();
                        fileDownload(JSON.stringify(response.data), 'export.'+moment(new Date()).format('YYYYMMDD-HHmm')+'.json');
                    });
                }
                } value="Submit">Download JSON with all configurations</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                        <Input
                            type='file' label='Upload'
                            onChange={(e) => {
                                const reader = new FileReader();
                                reader.onload = () => this.setState({importJSON: reader.result});
                                reader.readAsText(e.target.files[0]);
                            }} style={{display:"inline"}}
                        />
                <Button onClick={(e)=>{
                    const config = {
                        headers: {
                            'Authorization': "Bearer " + localStorage.token,
                            'Content-Type': 'application/json'
                        }
                    };
                    axios.post(`${CORE_URL}/transport`, this.state.importJSON, config).then(({data}) => {
                        this.setState({importJSON: null});
                    }).then(result => alert('Done!'));
                }} value="Submit">Upload JSON with all configurations</Button>
                        </FormGroup>
                    </Col></Row>
            </div>
        )
    }
}

export default Transports;
