import React, {Component} from 'reactn';
import ReactDOM from 'react-dom';
import FormWizzard from '../../components/FormWizzard/FormWizzard';
import Mpan from '../../components/Mpan/Mpan';
import FindAddress from '../../components/FindAddress/FindAddress';
import AddressDetails from '../../components/AddressDetails/AddressDetails';
import {Col, Form, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {withRouter} from "react-router-dom";
import moment from "moment";
import axios from "../../utils/Client";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {agreementsQuery, createUpdateAgreement, getQuote, getQuotesQuery} from "../../queries/Queries";
import {graphql, Mutation} from "react-apollo";
import Loading from "../../components/Loading";
import ExpiringAlert from "../../components/ExpiringAlert";
import AgreementOverview from "../../components/AgreementOverview";
import i18n from "../Pages/Login/i18n";


const businessTypes = [
{
    name: "Sole trader",
    value: "Sole trader",
},
{
    name: "Registered charity",
    value: "Registered charity",
}].concat(DYCE ? [{
    name: "Limited company",
    value: "Limited company",
}] : []);

const companySizes = [{
    name: "Micro business",
    value: "Micro business",

}, {
    name: "Non micro business",
    value: "Non micro business",
}];

const paymentMethods = [{
    name: "BACS",
    value: "BACS",

}, {
    name: "Variable DD",
    value: "Variable DD"
}, {
    name: "Fixed DD",
    value: "Fixed DD"
}].filter(({name}) => PAYMENT_METHODS.includes(name));
const saleTypes = [{
    name: "Renewal",
    value: "Renewal",

}, {
    name: "Acquisition",
    value: "Acquisition"
}];
class Step1 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
    return (document.createagreement.clientName.validity.valid
        && document.createagreement.businessType.validity.valid
        && document.createagreement.companyNumber.validity.valid);
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>{i18n.t('agreement.customer')}</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <Label for="clientName">{i18n.t('agreement.companyName')}:</Label>
            <Input type="text" name="clientName" id="clientName" value={this.props.agreement.state.agreement.clientName} onChange={(e) => this.props.onChange(e)} placeholder="Enter Company Name" required minLength="3" />
          </FormGroup>
          <FormGroup>
            <Label for="businessType">{i18n.t('agreement.businessType')}:</Label>
            <Input type="select" name="businessType" id="businessType" placeholder="Choose Business type" required onChange={(e) => this.props.onChange(e)} value={this.props.agreement.state.agreement.businessType}>
              {[{name: "Select one"},...businessTypes].map((option, index) => <option value={option.value} key={index}>{option.name}</option>)}
            </Input>
          </FormGroup>
          <FormGroup>
                <Label for="companySize">Company Size: </Label>
                <Input type="select" name="companySize" id="companySize" placeholder="Choose Company Size"
                       required onChange={(e) => this.props.onChange(e)}
                       value={this.props.agreement.state.agreement.companySize}>
                    {[{name: "Select one"}, ...companySizes].map((option, index) => <option value={option.value}
                                                                                            key={index}>{option.name}</option>)}
                </Input>
          </FormGroup>
          <FormGroup>
            <Label for="companyNumber">{i18n.t('agreement.companyRegistrationNumber')}:</Label>
            <Input type="text" name="companyNumber" id="companyNumber" value={this.props.agreement.state.agreement.companyNumber} onChange={(e) => this.props.onChange(e)} placeholder="Enter Company Registration Number" />
          </FormGroup>
        </Col>
      </Row>
    )
  }
};


class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isValidated = this.isValidated.bind(this);

  }
  isValidated() {
    return document.createagreement.mprn.validity.valid;
  }
  render() {
      let gasProduct = this.props.agreement.state.agreement.products.filter(({name}) => name === "Gas")[0];
      let electircityProduct = this.props.agreement.state.agreement.products.filter(({name}) => name === "Electricity")[0];
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2> Site information</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
            {gasProduct && <FormGroup>
                <Label for="mprn">MPRN:</Label>
                <Input type="text" name="mprn" id="mprn" value={this.props.agreement.state.agreement.mprn} onChange={(e) => this.props.onChange(e)}
                       placeholder="Enter MPRN number" disabled/>
            </FormGroup>
            }
            {electircityProduct &&
            <FormGroup>
                <Label for="mpan">MPAN:</Label>
                <Mpan mpan={this.props.agreement.state.agreement.mpan || ''}/>
            </FormGroup>
            }
          <FormGroup>
            <h3>Supply Address</h3><br />
            <FindAddress name="supply"
                         disabled={DYCE}
                         address={this.props.agreement.state.agreement.supplyAddress}
                         onChange={(address) => this.props.setAddress('supply', address)} />
          </FormGroup>
            {DYCE &&
            <FormGroup>
                <p>
                    <Input type="checkbox" name="climateChangeAgreement"
                           checked={this.props.agreement.state.agreement.climateChangeAgreement}
                           onChange={(e) => this.props.onChange(e)} id="climateChangeAgreement"/>
                    <label htmlFor="climateChangeAgreement" className="css-label"><i
                        className="icon ion-android-checkbox"/>Climate Change Agreement</label>
                </p>
            </FormGroup>
            }
            {/*{DYCE &&*/}
            {/*<FormGroup>*/}
            {/*    <p>*/}
            {/*        <Input type="checkbox" name="changeOfTenancy"*/}
            {/*               checked={this.props.agreement.state.agreement.changeOfTenancy}*/}
            {/*               onChange={(e) => this.props.onChange(e)} id="changeOfTenancy"/>*/}
            {/*        <label htmlFor="changeOfTenancy" className="css-label"><i className="icon ion-android-checkbox"/>Change*/}
            {/*            of Tenancy</label>*/}
            {/*    </p>*/}
            {/*</FormGroup>*/}
            {/*}*/}
            {/*{DYCE &&*/}
            {/*<FormGroup tag="fieldset">*/}
            {/*    <Label>Change of Tenancy Date</Label><br/>*/}
            {/*    <SingleDatePicker className={this.state.focusedEnd ? 'focused' : ''}*/}
            {/*                      date={this.props.agreement.state.agreement.changeOfTenancyDate ? moment(this.props.agreement.state.agreement.changeOfTenancyDate) : null} // momentPropTypes.momentObj or null*/}
            {/*                      numberOfMonths={1}*/}
            {/*                      onDateChange={date => {*/}
            {/*                          this.props.agreement.setState({*/}
            {/*                              agreement: {*/}
            {/*                                  ...this.props.agreement.state.agreement,*/}
            {/*                                  changeOfTenancyDate: date ?  date.format("YYYY-MM-DD") : null,*/}
            {/*                              }*/}
            {/*                          })*/}
            {/*                      }}*/}
            {/*                      displayFormat="DD/MM/YY"*/}
            {/*                      focused={this.state.focusedEnd} // PropTypes.bool*/}
            {/*                      onFocusChange={({focused}) => this.setState({focusedEnd: focused})} // PropTypes.func.isRequired*/}
            {/*                      id="agreement-changeOfTenancyDate" // PropTypes.string.isRequired,*/}
            {/*                      showDefaultInputIcon={true}*/}
            {/*                      inputIconPosition="after"*/}
            {/*    />*/}
            {/*</FormGroup>*/}
            {/*}*/}
        </Col>
      </Row>
    )
  }
};

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
      return (document.createagreement.bankAccountName.validity.valid
          && document.createagreement.sortCode.validity.valid
          && document.createagreement.accountNumber.validity.valid
          && document.createagreement.paymentMethod.validity.valid);
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Payment information</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup>
            <Label for="bankAccountName">Bank Account Name:</Label>
            <Input type="text" name="bankAccountName" id="bankAccountName" placeholder="Enter Bank Account Name"
                   onChange={(e) => this.props.onChange(e)} value={this.props.agreement.state.agreement.bankAccountName}
                   required={DYCE || !!this.props.agreement.state.agreement.bankAccountName
                   || !!this.props.agreement.state.agreement.sortCode
                   || !!this.props.agreement.state.agreement.accountNumber}/>
          </FormGroup>
          <FormGroup>
            <Label for="sortCode">Sort Code:</Label>
            <Input type="text" name="sortCode" id="sortCode" placeholder="Enter Sort Code"
                   onChange={(e) => this.props.onChange(e)} value={this.props.agreement.state.agreement.sortCode}
                   required={DYCE || !!this.props.agreement.state.agreement.bankAccountName
                   || !!this.props.agreement.state.agreement.sortCode
                   || !!this.props.agreement.state.agreement.accountNumber}/>
          </FormGroup>
          <FormGroup>
            <Label for="accountNumber">Account Number:</Label>
            <Input type="text" name="accountNumber" id="accountNumber" placeholder="Enter Account Number"
                   onChange={(e) => this.props.onChange(e)}  value={this.props.agreement.state.agreement.accountNumber}
                   required={DYCE || !!this.props.agreement.state.agreement.bankAccountName
                   || !!this.props.agreement.state.agreement.sortCode
                   || !!this.props.agreement.state.agreement.accountNumber}/>
          </FormGroup>
          <FormGroup>
            <Label for="paymentMethod">Payment Method: </Label>
            <Input type="select" name="paymentMethod" id="paymentMethod" placeholder="Select Payment Method"
                   onChange={(e) => this.props.onChange(e)} value={this.props.agreement.state.agreement.paymentMethod} required={DYCE}>
              {[{name: "Select one", value:''},...paymentMethods].map((option, index) => <option value={option.value} key={index}>{option.name}</option>)}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    )
  }
};
class Step4 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
      let v = [{key: 'primary', name: 'Primary'}, DYCE ? {key: 'creditCheck', name: 'Credit Check'} : {key: 'secondary', value:'Secondary'}, {key: 'billing', name: "Billing"} ]
          .map(type => ({
              type: type,
              valid: ['lastName', 'firstName', 'phone', 'email']
                    .reduce((acc, field) => acc && ((!this.props.agreement.state.agreement[type.key+'Contact'][field]
                                                        || ['postalCode', 'city', 'street'].reduce((acc, addrField) =>  acc && !!this.props.agreement.state.agreement[type.key+'Address'][addrField], true))
                        || !!this.props.agreement.state.agreement[type.key+'Contact']['sameAsPrimary']), true)}));
      v.forEach(({type, valid}) => {
          if(!valid){
              ReactDOM.render(<ExpiringAlert color="danger" message={`${type.name} Address is mandatory`} />, document.getElementById('alert').appendChild(document.createElement("div")));
          }
      })
      return document.createagreement.lastName_primary.validity.valid
          && document.createagreement.firstName_primary.validity.valid
          && document.createagreement.phone_primary.validity.valid
          && document.createagreement.email_primary.validity.valid
              && (this.props.agreement.state.agreement.billingContact.sameAsPrimary || document.createagreement.lastName_billing.validity.valid
              && document.createagreement.firstName_billing.validity.valid
              && document.createagreement.phone_billing.validity.valid
              && document.createagreement.email_billing.validity.valid)
          && v.reduce((acc, curr) => acc && curr.valid, true);
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Contacts details</h2>
        </Col>
        <Col className='col-12 step-body'>
          <Row >
            <Col className='col-6'>
              <AddressDetails required={true} name="primary"
                              address={this.props.agreement.state.agreement.primaryAddress}
                              contact={this.props.agreement.state.agreement.primaryContact}
                              onChange={(e) => this.props.onChange(e)}
                              setAddress={this.props.setAddress}
                              setContact={this.props.setContact}
                              setAddressAndContact={this.props.setAddressAndContact}
                              title="Primary Contact" isbilling={false} isprimary={true} />
            </Col>
            <Col className='col-6'>
                {DYCE
                    ?<AddressDetails required={false} name="creditCheck"
                                     address={this.props.agreement.state.agreement.creditCheckAddress}
                                     contact={this.props.agreement.state.agreement.creditCheckContact}
                                     onChange={(e) => this.props.onChange(e)}
                                     setAddress={this.props.setAddress}
                                     setContact={this.props.setContact}
                                     setAddressAndContact={this.props.setAddressAndContact}
                                     title="Credit Check Contact (optional)" isbilling={false} />
                    : <AddressDetails required={false} name="secondary"
                              address={this.props.agreement.state.agreement.secondaryAddress}
                              contact={this.props.agreement.state.agreement.secondaryContact}
                              onChange={(e) => this.props.onChange(e)}
                              setAddress={this.props.setAddress}
                              setContact={this.props.setContact}
                              setAddressAndContact={this.props.setAddressAndContact}
                              title="Secondary Contact (optional)" isbilling={false} />
                }
            </Col>
          </Row>
          <hr />
          <Row >
            <Col className='col-6'>
              <AddressDetails required={DYCE} name="billing"
                              address={this.props.agreement.state.agreement.billingAddress}
                              contact={this.props.agreement.state.agreement.billingContact}
                              onChange={(e) => this.props.onChange(e)}
                              setAddress={this.props.setAddress}
                              setContact={this.props.setContact}
                              setAddressAndContact={this.props.setAddressAndContact}
                              title="Billing Contact" isbilling={true} />
            </Col>
              {DYCE &&
              <Col className={'col-6'}>
                <h3>Previous Address</h3>
                <FormGroup>
                    <FindAddress name="previousAddress" address={this.props.agreement.state.agreement.previousAddress} onChange={(address) => this.props.setAddress('previous', address)} />
                </FormGroup>
              </Col>
              }
          </Row>
        </Col>
      </Row>
    )
  }
};
class Step5 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
    this.state = {
      focusedstart: false,
      focusedEnd: false,
    }
  }
  isValidated() {
      if(!document.createagreement.termsAndConditions.validity.valid){
          ReactDOM.render(<ExpiringAlert color="danger" message={"It is mandatory to accept Terms and Conditions"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      const endDate = this.props.agreement.state.agreement.endDate;
      const validStartAndEndDates = !endDate || moment(endDate).isBefore(moment(this.props.agreement.state.agreement.startDate));
      if(!validStartAndEndDates){
          ReactDOM.render(<ExpiringAlert color="danger" message={"Proposed Supply Start Date must be after Current Supplier Contract End Date"} />, document.getElementById('alert').appendChild(document.createElement("div")));
      }
      return document.createagreement.termsAndConditions.validity.valid && document.createagreement.letterofauthority.validity.valid && validStartAndEndDates ;
  }
  render() {
    return (
      <Row className='step'>
        <Col className='col-12 step-header'>
          <h2>Acceptance</h2>
        </Col>
        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-body'>
          <FormGroup tag="fieldset">
            <Label for="bankAccountName">Marketing consent:</Label><br />
            <p>
              <Input type="checkbox" name="mail" checked={this.props.agreement.state.agreement.mail} onChange={(e) => this.props.onChange(e)} id="mail" />
              <label for="mail" className="css-label"><i className="icon ion-android-checkbox" /> Mail</label>
            </p>
            <p>
              <Input type="checkbox" checked={this.props.agreement.state.agreement.telephone} onChange={(e) => this.props.onChange(e)} name="telephone" id="telephone" />
              <label for="telephone" className="css-label"><i className="icon ion-android-checkbox" /> Telephone</label>
            </p>
            <p>
              <Input type="checkbox" checked={this.props.agreement.state.agreement.SMS} id="SMS" name="SMS" onChange={(e) => this.props.onChange(e)} />
              <label for="SMS" className="css-label"><i className="icon ion-android-checkbox"></i> SMS</label>
            </p>
            <p>
              <Input type="checkbox" checked={this.props.agreement.state.agreement.email} id="email" name="email" onChange={(e) => this.props.onChange(e)} />
              <label for="email" className="css-label"><i className="icon ion-android-checkbox"></i> Email</label>
            </p>

          </FormGroup>
          <FormGroup tag="fieldset">
            <Label>Proposed Supply Start Date</Label><br />
            <SingleDatePicker className={this.state.focusedstartdate ? 'focused' : ''} date={this.props.agreement.state.agreement.startDate ? moment(this.props.agreement.state.agreement.startDate) : null} // momentPropTypes.momentObj or null
              numberOfMonths={1}
              onDateChange={date => {
                this.props.agreement.setState({
                  agreement: {
                    ...this.props.agreement.state.agreement,
                    startDate: date ? date.format("YYYY-MM-DD") : null,
                  }
                })
              }}
               displayFormat="DD/MM/YY"
              showDefaultInputIcon={true}
              inputIconPosition="after"
              disabled={DYCE && this.props.agreement.props.getQuote.getQuoteByProcessInstanceId.startDate}
              isOutsideRange={day => DYCE && day.isBefore(moment().add(13, 'd'))}
              focused={this.state.focusedstartdate} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focusedstartdate: focused })} // PropTypes.func.isRequired
              id="agreement-start-date" // PropTypes.string.isRequired,
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <Label>Current Supplier Contract End Date</Label><br />
            <SingleDatePicker className={this.state.focusedEnd ? 'focused' : ''} date={this.props.agreement.state.agreement.endDate ? moment(this.props.agreement.state.agreement.endDate) : null} // momentPropTypes.momentObj or null
              numberOfMonths={1}
              onDateChange={date => {
                this.props.agreement.setState({
                  agreement: {
                    ...this.props.agreement.state.agreement,
                    endDate: date ? date.format("YYYY-MM-DD") : null,
                  }
                })
              }}

               displayFormat="DD/MM/YY"
              focused={this.state.focusedEnd} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })} // PropTypes.func.isRequired
              id="agreement-end-date" // PropTypes.string.isRequired,
              showDefaultInputIcon={true}
              isOutsideRange={() => false}
              inputIconPosition="after"
              disabled={DYCE && this.props.agreement.props.getQuote.getQuoteByProcessInstanceId.endDate}
            />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <Label for="saleType">Sale Type: </Label>*/}
          {/*  <Input type="select" name="saleType" id="saleType" placeholder="Select Sale Type" value={this.props.agreement.state.agreement.saleType} onChange={(e) => this.props.onChange(e)}>*/}
          {/*    {[{name: "Select one"},...saleTypes].map((option, index) => <option value={option.value} key={index}>{option.name}</option>)}*/}
          {/*  </Input>*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Label for="letterofauthority">Client Letter of Authority</Label>
            <Input type="file" name="letterofauthority" id="letterofauthority" onChange={(e) => this.props.setFile(e)} required={DYCE}/>
            <FormText color="muted">
            </FormText>
          </FormGroup>
            {DYCE && <FormGroup tag="fieldset">
                <Label>Letter of Authority Start Date</Label><br/>
                <SingleDatePicker className={this.state.focusedloaStartDate ? 'focused' : ''}
                                  date={this.props.agreement.state.agreement.loaStartDate ? moment(this.props.agreement.state.agreement.loaStartDate) : null} // momentPropTypes.momentObj or null
                                  numberOfMonths={1}
                                  onDateChange={date => {
                                      this.props.agreement.setState({
                                          agreement: {
                                              ...this.props.agreement.state.agreement,
                                              loaStartDate: date ? date.format("YYYY-MM-DD") : null,
                                          }
                                      })
                                  }}
                                  displayFormat="DD/MM/YY"
                                  isOutsideRange={() => false}
                                  showDefaultInputIcon={true}
                                  inputIconPosition="after"
                                  focused={this.state.focusedloaStartDate} // PropTypes.bool
                                  onFocusChange={({focused}) => this.setState({focusedloaStartDate: focused})} // PropTypes.func.isRequired
                                  id="agreement-loaStartDate" // PropTypes.string.isRequired,
                />
            </FormGroup>
            }
            {DYCE &&
            <FormGroup tag="fieldset">
                <Label>Letter of Authority End Date</Label><br/>
                <SingleDatePicker className={this.state.focusedloaEndDate ? 'focused' : ''}
                                  date={this.props.agreement.state.agreement.loaEndDate ? moment(this.props.agreement.state.agreement.loaEndDate) : null} // momentPropTypes.momentObj or null
                                  numberOfMonths={1}
                                  onDateChange={date => {
                                      this.props.agreement.setState({
                                          agreement: {
                                              ...this.props.agreement.state.agreement,
                                              loaEndDate: date ? date.format("YYYY-MM-DD") : null,
                                          }
                                      })
                                  }}
                                  displayFormat="DD/MM/YY"
                                  isOutsideRange={() => false}
                                  focused={this.state.focusedloaEndDate} // PropTypes.bool
                                  onFocusChange={({focused}) => this.setState({focusedloaEndDate: focused})} // PropTypes.func.isRequired
                                  id="agreement-loaEndDate" // PropTypes.string.isRequired,
                                  showDefaultInputIcon={true}
                                  inputIconPosition="after"
                />
            </FormGroup>
            }
          <FormGroup tag="fieldset">
            <Label for="termsAndConditions">Terms & Conditions</Label><br />
            <Input type="checkbox" checked={this.props.agreement.state.agreement.termsAndConditions}  onChange={(e) => this.props.onChange(e)} id="termsAndConditions" name={"termsAndConditions"} required/>
            <label htmlFor="termsAndConditions" className="css-label"><i className="icon ion-android-checkbox"></i> <a href="#/terms-and-conditions">Terms & Conditions Received</a></label> <br />
          </FormGroup>
        </Col>
      </Row>
    )
  }
};
class Step6 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }
  isValidated() {
    return true;
  }
  render() {
    return (
      <AgreementOverview agreement={this.props.agreement.state.agreement}/>
    )
  }
};
class Step7 extends Component {
  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);

  }
  isValidated() {
    return true;
  }
  render() {
    return (
      <Row className='step'>
        <div className="done-step"> 
            <i className="icon ion-android-checkmark-circle"></i>
            <p className="text">Thank you! Your Agreement is created. </p>
        </div>
      </Row>
    )
  }
};
class CreateAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          name: i18n.t('agreement.customerStage1'),
          component: <Step1 agreement={this} onChange={ this.handleInput} />
        }, {
          name: 'Site',
          component: <Step2 agreement={this} onChange={ this.handleInput}
                            setAddress={(addressType, address) =>
              this.setState({...this.state, agreement:{...this.state.agreement, [addressType+"Address"]:address}})}
                            setContact={(contactType, contact) =>
              this.setState({...this.state, agreement:{...this.state.agreement, [contactType+"Contact"]:contact}})}
                            setAddressAndContact={(addressType, address, contactType, contact) =>
              this.setState({agreement:{...this.state.agreement, [addressType+"Address"]:address, [contactType+"Contact"]:contact}})}/>
        },
        {
          name: 'Payment',
          component: <Step3 agreement={this} onChange={this.handleInput}/>
        },
        {
          name: 'Contacts',
          component: <Step4 agreement={this}
                            onChange={this.handleInput}
                            setAddress={(addressType, address) =>
              this.setState({agreement:{...this.state.agreement, [addressType+"Address"]:address}})}
                            setContact={(contactType, contact) =>
              this.setState({agreement:{...this.state.agreement, [contactType+"Contact"]:contact}})}
                            setAddressAndContact={(addressType, address, contactType, contact) =>
              this.setState({agreement:{...this.state.agreement, [addressType+"Address"]:address, [contactType+"Contact"]:contact}})}/>

        },
        {
          name: 'Acceptance',
          component: <Step5 agreement={this} onChange={this.handleInput} setFile={(e) => {
              this.setState({
                  ...this.state,
                  agreement: {...this.state.agreement, loaPresent: e.target.files.length > 0},
                  file: e.target.files[0]
              });
          }
          }/>
        },
        {
          name: 'Overview',
          component: <Step6 agreement={this} onChange={this.handleInput}/>
        },
        {
          name: 'Done',
          component: <Step7 agreement={this} onChange={this.handleInput}/>
        },
      ]



    }
    this.handleInput = this.handleInput.bind(this);
  }
    componentWillReceiveProps(newProps){

    }
  handleInput(e) {
    let state = {...this.agreement.state.agreement};

    if (e.target.name === '') {
      state['mpan'] = (document.createagreement.mpan && document.createagreement.mpan.value);
    } else {
      if (e.target.type === 'checkbox') {
        state[e.target.name] = !state[e.target.name];
      } else {
        state[e.target.name] = e.target.value;
      }
    }


    this.agreement.setState({
      agreement: state
    })
  }
  render() {
      document.querySelectorAll('input[type="number"]').forEach(target => target.addEventListener('wheel', (e) => e.target.blur()));
      if(!this.state.agreement){
        if(!this.props.getQuote.loading && this.props.getQuote.getQuoteByProcessInstanceId){
            let {id, statusReason, extraData, ...agreement} = {...this.props.getQuote.getQuoteByProcessInstanceId};
            extraData = JSON.parse(extraData);
            agreement.mprn = extraData.products.filter(product => product.name === 'Gas').reduce((acc, curr) => curr, {meterPoint:null}).meterPoint;
            agreement.aq = extraData.products.filter(product => product.name === 'Gas').reduce((acc, curr) => curr, {meterPoint:null}).annualQuantity;
            agreement.aqIndustry = extraData.products.filter(product => product.name === 'Gas').reduce((acc, curr) => curr, {meterPoint:null}).annualQuantityIndustry;
            agreement.mpan = extraData.products.filter(product => product.name === 'Electricity').reduce((acc, curr) => curr, {meterPoint:null}).meterPoint;
            agreement.primaryAddress = extraData.primaryAddress ? extraData.primaryAddress : {};
            agreement.secondaryAddress = extraData.secondaryAddress ? extraData.secondaryAddress : {};
            agreement.billingAddress = extraData.billingAddress ? extraData.billingAddress : {};
            agreement.primaryContact = extraData.primaryContact ? extraData.primaryContact : {};
            agreement.secondaryContact = extraData.secondaryContact ? extraData.secondaryContact : {};
            agreement.billingContact = extraData.billingContact ? extraData.billingContact : {};
            if(DYCE){
                agreement.creditCheckAddress = {};
                agreement.creditCheckContact = {};
                agreement.previousAddress = {};
            }
            agreement.extraData = extraData;
            agreement.sourceQuoteId = id;
            this.setState({agreement: agreement});
        }
        return <Loading/>
      } else {
          let {id, clientName, date, ownerOrganizationId, subOwnerOrganizationId, ownerUserId, processInstanceId,
              smartMeter, status, tariffName, startDate, endDate, extraData,
              ...agreementExtraData} =  this.state.agreement;
          delete agreementExtraData.date;
          if(agreementExtraData.products) {
              agreementExtraData.products = agreementExtraData.products.map(({__typename, ...product}) => product);
              agreementExtraData.extraData = JSON.stringify({
                  ...extraData,
                  ...agreementExtraData
              });
              let gasProduct = agreementExtraData.products.filter(({name}) => name === "Gas")[0];
              if (gasProduct) {
                  gasProduct.meterPoint = agreementExtraData.mprn;
              }
              delete agreementExtraData.mprn;
              let electircityProduct = agreementExtraData.products.filter(({name}) => name === "Electricity")[0];
              if (electircityProduct) {
                  electircityProduct.meterPoint = agreementExtraData.mpan;
              }
              delete agreementExtraData.mpan;
          }
          const agreement = {status : {key : 'DRAFT'}, clientName, ownerOrganizationId, subOwnerOrganizationId, ownerUserId, processInstanceId, smartMeter, tariffName, startDate, endDate, extraData:agreementExtraData.extraData};
          return (
              this.props.getQuote.loading ? <Loading/>
                  : <div className="">
                      <Row className='subheader'>
                          <Col className='col-8'>
                              <h1>{i18n.t('agreement.createAgreements')}</h1>
                          </Col>
                      </Row>
                      <Form name="createagreement" onSubmit={(e) => { e.preventDefault() }}>
                          <Mutation mutation={createUpdateAgreement} variables={{agreement: agreement}}
                                    refetchQueries={[
                                        {query: getQuotesQuery}, {query: getQuotesQuery, variables:{organizationId: ownerOrganizationId, username:null}}, {query: getQuotesQuery, variables:{organizationId: ownerOrganizationId, username: this.global.username}},
                                        {query: agreementsQuery}, {query: agreementsQuery, variables:{organizationId: ownerOrganizationId, username:null}}, {query: agreementsQuery, variables:{organizationId: ownerOrganizationId, username: this.global.username}}]}>
                              {(createAgreement, {data}) => (
                                  <FormWizzard steps={this.state.steps} onLastStep={() => {
                                      createAgreement();
                                      if(this.state.file) {
                                          const data = new FormData();
                                          data.append('file', this.state.file);
                                          axios.post(SALESPORTAL_URL + "/attachment/" + this.props.match.params.processInstanceId + "/LOA",
                                              data,
                                              {headers: {'Authorization': "Bearer " + localStorage.token}});
                                      }
                                  }}/>
                              )}
                          </Mutation>
                      </Form>
                  </div>
          )
      }
    
  }
}

export default graphql(getQuote, {
    name: 'getQuote',
    options: props => ({
        variables: { processInstanceId: props.match.params.processInstanceId }
    }),
}) (withRouter(CreateAgreement));