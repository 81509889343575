import React, {Component} from 'reactn';
import {Mutation, Query} from 'react-apollo';
import {createTaskCommentMutation, getTaskCommentsQuery} from "../../queries/Queries";
import {Col, Row} from "reactstrap";
import Form from "react-jsonschema-form";
import commentschema from "../../schemas/taskcomment.schema.json";
import Loading from "../Loading";
import Textarea from "react-textarea-autosize";

class TasksComments extends Component {
    constructor(props){
        super(props);
        this.state = {comment:""};
    }

    render() {
        const taskReadonly = !this.props.task ||  this.props.task.status === 'Closed' ||  this.props.task.status === 'Cancelled';
        return this.props.task

            ? <div className="task-comments-cnt">
                <h6>Comments</h6>
                <Query query={getTaskCommentsQuery} variables={{taskId: this.props.task.id, service: this.props.task.service}}>
                {({ loading, error, data }) => {
                    if (loading) return (<Loading/>);
                    if (error) return `Error! ${error.message}`;

                    return (
                        data.getTaskComments.map(comment =>
                            <Row className="comments-list">
                                <Col  className="avatar"><img src={`${CORE_URL}/avatar/${comment.author}`}/></Col>
                                <Col >
                                    <Row>
                                        <Col className="comment-info">{comment.author} <span className="date">{comment.time ? new Date(comment.time).toLocaleString() : ""}</span></Col>
                                        {/*<Col className="moredetails pull-right"><i class="icon ion-ios-more"></i></Col>*/}
                                    </Row>
                                    <Row className="message"><Col>{comment.message}</Col></Row></Col>
                            </Row>
                         )
                    );
                }}
                </Query>
                {!taskReadonly && <Mutation mutation={createTaskCommentMutation} refetchQueries={[{
                        query: getTaskCommentsQuery,
                        variables: {taskId: this.props.task.id, service: this.props.task.service}
                    }]}>
                    {(createTaskComment, {data}) => (<Form schema={commentschema}
                                                           uiSchema={{
                                                               "ui:widget": (props) => {
                                                                   return <div className="comments-text-area"><div className="user">
                                                                       <img src={`${CORE_URL}/avatar/${this.global.username}`}/>
                                                                   </div>
                                                                       <Textarea value = {
                                                                           typeof props.value === "undefined" ? "" : props.value
                                                                       }
                                                                                 placeholder = "Post a comment..."
                                                                                 onChange = {
                                                                                     (event) => {
                                                                                         props.onChange(event.target.value)
                                                                                     }
                                                                                 }
                                                                       /></div>
                                                               },
                                                               "classNames": 'comments-post-cnt',
                                                           }}
                                                           formData={this.state.comment}
                                                           ref={"form"}
                                                           onSubmit={({formData}) => {
                                                               createTaskComment({
                                                                   variables: {
                                                                       taskId: this.props.task.id,
                                                                       comment: {message: formData},
                                                                       service: this.props.task.service,
                                                                   }
                                                               });
                                                               this.setState({comment:""});
                                                               return true;
                                                }}
                                                >
                                                  <div className="buttons">
                                                        <button type="submit" className="btn btn-primary">Comment</button>
                                                        <button type="reset" className="btn btn-secondary">Cancel</button>
                                                    </div>
                                                </Form>)
                    }
                </Mutation>
                }
            </div>
            : "";
    }
}




export default TasksComments;
