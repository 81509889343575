import React, {Component} from 'react';
import JsxParser from 'react-jsx-parser';
import InfoCard from '../../components/InfoCard';
import QueryDataGrid1 from "../../components/QueryDataGrid1";
import {withRouter} from 'react-router';
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import NavTabs from "../../components/NavTabs";
import {
    attachmentIconFormatter,
    billingDocumentTypeFormatter,
    cdnDownloadFormatter,
    dateFormatter,
    dateTimeFormatter,
    downloadFormatter,
    imageFormatter,
    invoiceEstateFormatter,
    jsonValueFormatter,
    noteIconFormatter,
    paymentCompleteFormatter,
    periodFormatter,
    prefCommChannelFormatter,
    priceCustomFormatter,
    priceFormatter,
    productFormatter,
    reportedAmountFormatter,
    rolesFormatter,
    jsonataFormatter,
    iconFormatter,
    hiddenColFormatter,
    menuFotmatter,
    downloadLinkFormatter
} from "../../utils/Formatters";
import i18n from '../../views/Pages/Login/i18n';
import ProcessesTable from "../ProcessesTable";
import QueryInfoCard from "../QueryInfoCard";
import QueryForm from "../QueryForm";
import TableHeader from "../../components/TableHeader";
import BillsTab from "../../components/BillsTab";
import QueryBillsTabWrapper from "../QueryBillsTabWrapper";
import QueryProcessesTabWrapper from "../QueryProcessesTabWrapper";
import ReadingsTab from "../../components/ReadingsTab";
import QueryNotesAndAttachments from "../QueryNotesAndAttachments";
import QueryBalance from "../QueryBalance";
import QueryLink from "../QueryLink";
import QueryBillsDetail from "../QueryBillsDetail";
import BusinessPartnerHeader from "../BusinessPartnerHeader";
import StartProcess from "../StartProcess";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import TableHeader2 from "../TableHeader2";
import SignContractButton from "../SignContractButton";
import QuerySignContractButton from "../QuerySignContractButton";
import ConfigurableReport from "../ConfigurableReport";
import ConfigurableReports from "../ConfigurableReports";
import ServiceRequests from "../../views/ServiceRequests";
import {Step, Steps, Wizard} from 'react-albus';
import FormA from '../../views/SwitchingForm/FormA';
import StepA from '../../views/SwitchingForm/StepA';
import JsonSchemaForm from 'react-jsonschema-form';
import MeterFields from '../../views/MeterFields/MeterFields';
import QueryWrapper from "../QueryWrapper";
import DisplayValue from "../DisplayValue";

class GenericView extends Component {

    constructor(props) {
        super(props);
        this.getLayoutByName = this.getLayoutByName.bind(this);
        const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
        this.queries = this.getQueries(layout);

    }

    getLayoutByName(layoutName) {
        return this.props.configuration.uiLayouts.reduce((result, current) => {
            if (current.name === layoutName) {
                result = current;
            }
            return result;
        });
    }

    getQueries() {

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        for (let i = 0; i < uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    render() {

        if (this.props.not) {
            return (
                <Switch>
                    <Route exact={true} path={"/view/:name" + this.props.path} render={(props) => {
                        return null;
                    }}/>
                    <Route render={(props) => {
                        const {path, not, ...newProps} = this.props;
                        return (<GenericView {...newProps} configuration={this.props.configuration}/>);
                    }}/>
                </Switch>
            )
        } else if (this.props.path) {
            return (
                <Route path={"/view/:name" + this.props.path} exact={true} render={(props) => {
                    const {path, ...newProps} = this.props;
                    return (<GenericView {...newProps} configuration={this.props.configuration}/>)
                }}/>
            )
        }

        const layout = this.getLayoutByName(this.props.layout || this.props.match.params.name);
        const queries = this.getQueries(layout);
        const configuration = this.props.configuration;


        return (<JsxParser
            bindings={{
                ...this.props,
                queries: queries,
                configuration: configuration,
                i18n: i18n,
                productFormatter: productFormatter,
                priceFormatter: priceFormatter,
                priceCustomFormatter: priceCustomFormatter,
                dateFormatter: dateFormatter,
                dateTimeFormatter: dateTimeFormatter,
                periodFormatter: periodFormatter,
                reportedAmountFormatter: reportedAmountFormatter,
                rolesFormatter: rolesFormatter,
                imageFormatter: imageFormatter,
                noteIconFormatter: noteIconFormatter,
                attachmentIconFormatter: attachmentIconFormatter,
                paymentCompleteFormatter: paymentCompleteFormatter,
                downloadFormatter: downloadFormatter,
                cdnDownloadFormatter: cdnDownloadFormatter,
                invoiceEstateFormatter: invoiceEstateFormatter,
                jsonValueFormatter: jsonValueFormatter,
                billingDocumentTypeFormatter: billingDocumentTypeFormatter,
                prefCommChannelFormatter: prefCommChannelFormatter,
                jsonataFormatter: jsonataFormatter,
                hiddenColFormatter: hiddenColFormatter,
                iconFormatter: iconFormatter,
                menuFotmatter: menuFotmatter,
                MeterFields: MeterFields,
                downloadLinkFormatter: downloadLinkFormatter
            }}
            components={{
                Dropdown, DropdownToggle, DropdownItem, DropdownMenu,
                Row, Col, Nav, NavItem, TabContent,
                TabPane, InfoCard, Card, CardText,
                Link, Switch, Route, Redirect,
                QueryDataGrid1, Form, FormGroup, NavTabs,
                ProcessesTable, QueryForm, QueryInfoCard,
                GenericView, TableHeader, BillsTab, ReadingsTab,
                QueryNotesAndAttachments, QueryBillsTabWrapper, QueryBalance,
                QueryProcessesTabWrapper, QueryBillsDetail, QueryLink, BusinessPartnerHeader,
                StartProcess,
                CardHeader, CardTitle, CardBody,
                PaginatedQueryDataGrid,
                TableHeader2, SignContractButton, QuerySignContractButton, QueryWrapper, DisplayValue,
                ConfigurableReport, ConfigurableReports, ServiceRequests,
                Steps, Step, Wizard, FormA, StepA, JsonSchemaForm
            }}
            jsx={layout.jsx}
            showWarnings={true}
        />)
    }
}

export default withRouter(GenericView)