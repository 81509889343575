import React, {Component} from 'reactn';
import {withRouter} from 'react-router';
import i18n from "../../views/Pages/Login/i18n";
import {
    Button,
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import QueryDataGrid from "../QueryDataGrid/QueryDataGrid";
import {
    agreementsQuery, getAgreementStatusesQuery,
    getAgreementStatusFlowFromQuery,
    getQuoteStatusesQuery, getQuoteStatusFlowFromQuery,
    setAgreementStatusMutation
} from "../../queries/Queries";
import {ApolloConsumer, Mutation, Query} from "react-apollo";
import Loading from "../Loading";
import fileDownload from "js-file-download";
import axios from "../../utils/Client";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";

//---------------------------------------------------------

class NoAgreements extends Component {
    constructor(props) {
        super(props)
        // if (document.getElementById('filters-Tariffs')) {
        //     document.getElementById('filters-Tariffs').remove();
        // }
    }

    render() {
        return (
            <div className="nosalesagent">
                <i className="icon ion-android-bookmark"></i>
                <p className="text">There are no Agreements uploaded yet.</p>
            </div>
        );
    }
}

//---------------------------------------------------------

class AgreementsGrid extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            userSearch: '',
            enabledSearch: ''
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggleModal = () => {
        this.setState({modalOpen: !this.state.modalOpen})
    }

    toggleReasonModal = (statusToSet, setAgreementStatus, agreementId) => {
        this.setState({reasonModalOpen: !this.state.reasonModalOpen, statusToSet: statusToSet, setAgreementStatus, agreementId})
    }

    toggleUploadModal = (statusToSet, setAgreementStatus, agreementId, processInstanceId) => {
        this.setState({uploadModalOpen: !this.state.uploadModalOpen, statusToSet, setAgreementStatus, agreementId, processInstanceId})
    }

    render() {
        return (  <ApolloConsumer>
            {client => <div> <Mutation mutation={setAgreementStatusMutation} >
            {(setAgreementStatus) => (<div><Query query={getAgreementStatusesQuery}>
            {(result) => <PaginatedQueryDataGrid dataPath={'getAgreements.content'}
                                                 paginationPath={'getAgreements'} keyField={'id'}
                                                 serverSidePaging={true}
                                                 query={agreementsQuery}
                                                 variables={{
                                                     organizationId: this.props.organizationId,
                                                     username: this.props.username
                                                 }}
                                                 columns={[
                                                     {
                                                         dataField: 'documentNumber',
                                                         text: i18n.t('agreement.number'),
                                                         sort: true
                                                     },
                                                     {
                                                         dataField: 'date',
                                                         text: i18n.t('agreement.creationDate'),
                                                         sort: true,
                                                         formatter: (cell, row) => {
                                                             return new Date(cell.time).toLocaleDateString();
                                                         }
                                                     },
                                                     {
                                                         dataField: 'clientName',
                                                         text: i18n.t('agreement.clientName'),
                                                         sort: true
                                                     },
                                                     {
                                                         dataField: 'ownerOrganizationName',
                                                         text: i18n.t('agreement.salesOrganization'),
                                                         sort: true,

                                                     },
                                                     {
                                                         dataField: 'subOwnerOrganizationName',
                                                         text: i18n.t('agreement.associateOrganization'),
                                                         sort: true,
                                                         hidden: !this.global.permissions.includes('Quotes.ViewSubbrokerColumn')
                                                     },
                                                     {
                                                         dataField: 'extraData',
                                                         text: i18n.t('agreement.postalCode'),
                                                         sort: true,
                                                         formatter: (cell, row) => {
                                                             return JSON.parse(cell).supplyAddress.postalCode
                                                         }
                                                     },
                                                     {
                                                         dataField: 'extraData',
                                                         text: i18n.t('agreement.meterPoint'),
                                                         sort: true,

                                                         formatter: (cell, row) => {
                                                             return JSON.parse(cell).meterPoint
                                                         }
                                                     },
                                                     ...(!DYCE ? [{
                                                         dataField: 'tariff',
                                                         text: i18n.t('agreement.tariffName'),
                                                         sort: true
                                                     }] : []),
                                                     {
                                                         dataField: 'products',
                                                         text: i18n.t('agreement.products'),
                                                         formatter: (cell, row) => {
                                                             return row.products.map(e => e.name).join(', ')
                                                         }
                                                     },
                                                     ...(DYCE ? [{
                                                         dataField: 'agreement.contractDuration',
                                                         text: i18n.t('agreement.contractDuration'),
                                                         sort: true
                                                     }] : []),
                                                     {
                                                         dataField: 'status.name',
                                                         text: i18n.t('agreement.status'),
                                                         sort: true,

                                                     },
                                                     {
                                                         dataField: 'startDate',
                                                         text: i18n.t('agreement.startDate'),
                                                         sort: true,
                                                         formatter: (cell, row) => {
                                                             return cell ? new Date(cell).toLocaleDateString() : null;
                                                         }
                                                     },
                                                     ...(!DYCE
                                                         ? [{
                                                             dataField: 'endDate',
                                                             text: i18n.t('agreement.endDate'),
                                                             sort: true,
                                                             formatter: (cell, row) => {
                                                                 return cell ? new Date(cell).toLocaleDateString() : null;
                                                             }
                                                         }]
                                                         : [])

                                                 ]}
                                                 menuItems={async(obj) =>  {
                                                     const { data } = await client.query({
                                                         query: getAgreementStatusFlowFromQuery,
                                                         variables: { from: {
                                                                 key: obj.status.key
                                                             }}
                                                     });

                                                     return [...data.getAgreementStatusFlowFrom.map(({to}) => ({name: to.action, callback: () => {
                                                             if (to.key === "SIGNED") {
                                                                 this.toggleUploadModal(to, setAgreementStatus, obj.id, obj.processInstanceId);
                                                                 const promise = new Promise((resolve, reject) =>{
                                                                     this.setState({statusPromiseResolve : resolve});
                                                                 });

                                                                 return promise;
                                                             } else if (!to.requireReason) {

                                                                 return setAgreementStatus({
                                                                     variables: {
                                                                         agreementId: obj.id,
                                                                         agreementStatus: {key: to.key},
                                                                         // statusReason: this.state.statusReason
                                                                     }
                                                                 });

                                                             } else {
                                                                 this.toggleReasonModal(to, setAgreementStatus, obj.id);
                                                                 const promise = new Promise((resolve, reject) =>{
                                                                     this.setState({statusPromiseResolve : resolve});
                                                                 });

                                                                 return promise;
                                                             }
                                                         }})),
                                                         {name:'Details', callback:(obj) => {
                                                             if(obj.obId) {
                                                                 this.props.history.push('/agreements-old/' + obj.obId);
                                                             } else {

                                                                 this.props.history.push('/agreements/' + obj.id);
                                                             }
                                                             return Promise.resolve('done');
                                                         }},
                                                         ...(obj.documentNumber === null ? [{name: 'Print', callback:(obj) => {
                                                                 return axios.get(`${SALESPORTAL_URL}/contractPrint/${obj.id}`, {
                                                                     headers: {'Authorization': "Bearer " + localStorage.token},
                                                                     responseType: 'blob'
                                                                 }).then((response) => {
                                                                     fileDownload(response.data, 'Contract ' + obj.clientName + '.pdf');
                                                                 })
                                                             }}] : [])
                                                     ];



                                                 }}
                                                 noDataView={<NoAgreements/>}

                                                 defaultSorted={{
                                                     dataField: 'date',
                                                     order: 'desc'
                                                 }}
                                                 filters={[
                                                     {
                                                         type: 'text',
                                                         fields: ['extraData.documentNumber', 'clientName', 'ownerOrganizationName', 'subOwnerOrganizationName', 'extraData', 'extraData.products'],
                                                         placeholder: i18n.t("agreement.searchPrompt"),
                                                         classes: "search",
                                                         cols: 2
                                                     },
                                                     {
                                                         type: 'dropdown',
                                                         fields: ['extraData.products'],
                                                         placeholder: "All Products",
                                                         possibleValues: [{key: 'Gas', value: 'Gas'}],
                                                         cols: 2
                                                     },
                                                     {
                                                         type: 'dropdown',
                                                         fields: ['status.key'],
                                                         possibleValues:  result.loading ? [] : result.data.getAgreementStatuses.map(({key, name}) => ({
                                                             key,
                                                             value: name
                                                         })),
                                                         placeholder: "All Statuses",
                                                         cols: 2
                                                     },
                                                     {
                                                         type: 'date',
                                                         label: 'Start',
                                                         fields: ['startDate'],
                                                         cols: 3
                                                     },
                                                     {
                                                         type: 'date',
                                                         label: 'End',
                                                         fields: ['endDate'],
                                                         cols: 3
                                                     }
                                                 ]}
            />
            }
            </Query>
                <Modal isOpen={this.state.reasonModalOpen} toggle={this.toggleReasonModal}
                       className="quote-preview-model">
                    <ModalHeader toggle={this.toggleReasonModal}>
                        Enter reason:
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Input type={"select"} value={this.state.statusReason}
                                       onChange={(e) => this.setState({statusReason: e.target.value})}>
                                    <option/>
                                    {AGREEMENT_REJECTION_REASONS.split(",").map(reason => <option value={reason}>{reason}</option>)}
                                </Input>
                            </FormGroup>
                        </Form>

                        <Button color='primary' onClick={() => {
                            this.state.setAgreementStatus({
                                variables: {
                                    agreementId: this.state.agreementId,
                                    agreementStatus: this.state.statusToSet,
                                    // statusReason: this.state.statusReason
                                }
                            }).then(result => this.state.statusPromiseResolve());
                            this.toggleReasonModal(null, null, null);
                            this.setState({statusReason: null});
                        }}>Submit</Button>

                        <Button color='secondary' onClick={() => {
                            this.toggleReasonModal(null, null, null);
                            this.setState({statusReason: null});
                        }}>Cancel</Button>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.uploadModalOpen} toggle={this.toggleUploadModal}
                       className="quote-preview-model">
                    <ModalHeader toggle={this.toggleUploadModal}>
                        Attach Signed Contract:
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Input type={"file"}
                                       onChange={(e) => this.setState({file: e.target.files[0]})}>
                                </Input>
                            </FormGroup>
                        </Form>

                        <Button color='primary' onClick={() => {
                            if (this.state.file) {
                                const data = new FormData();
                                data.append('file', this.state.file);
                                axios.post(SALESPORTAL_URL + "/attachment/" + this.state.processInstanceId + "/CONTRACT",
                                    data,
                                    {headers: {'Authorization': "Bearer " + localStorage.token}}).then(
                                    () => {
                                        this.state.setAgreementStatus({
                                            variables: {
                                                agreementId: this.state.agreementId,
                                                agreementStatus: this.state.statusToSet,
                                                // statusReason: this.state.statusReason
                                            }
                                        }).then(result => this.state.statusPromiseResolve());
                                        this.toggleUploadModal(null, null, null);
                                        this.setState({statusReason: null});
                                    }
                                );
                            } else {
                                this.state.setAgreementStatus({
                                    variables: {
                                        agreementId: this.state.agreementId,
                                        agreementStatus: this.state.statusToSet,
                                        // statusReason: this.state.statusReason
                                    }
                                }).then(result => this.state.statusPromiseResolve());
                                this.toggleUploadModal();
                                this.setState({statusReason: null});
                            }
                        }}>Mark Contract As Signed</Button>

                        <Button color='secondary' onClick={() => {
                            this.toggleUploadModal();
                            this.setState({statusReason: null});
                        }}>Cancel</Button>
                    </ModalBody>
                </Modal>
                </div>
                )}
            </Mutation>
            </div>}
            </ApolloConsumer>
        )
    }
}

export default withRouter(AgreementsGrid);