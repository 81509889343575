import React, { useState } from 'react';
import {withApollo} from "react-apollo";
import {JsonEditor} from "jsoneditor-react";
import {Button} from "reactstrap";
import {completeTaskMutation, getTasksQuery} from "../../queries/Queries";

const JsonEditorA = (props) => {

    const [data, setData] = useState({});

    const handleChange = (data) => setData(data);

    const handleClick = () =>{
        const vars = [{name: props.taskDetails.outputVariableName || "formData", value: data, type: 'Json'}];
        props.client.mutate({
            variables: { task: {id: props.taskDetails.id, variables: vars}, service: props.taskDetails.service },
            mutation: completeTaskMutation,
            refetchQueries: [{query: getTasksQuery}]
        })
    };

    return (
        <div>
            <JsonEditor {...props} sortObjectKeys
                        onChange={handleChange}/>
            <Button onClick={handleClick}>Save and Continue</Button>
        </div>
    )
}

export default withApollo(JsonEditorA);
