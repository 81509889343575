import React from 'reactn';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import gql from "graphql-tag";
import {Query} from 'react-apollo';
import {Input} from "reactstrap";
import {getUsers} from "../queries/Queries"
import ReactDOM from "react-dom";
import CustomerSearch from "../components/CustomerSearch";
import 'react-dates/initialize';
import { SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Textarea from "react-textarea-autosize";
import moment from "moment";

class UserDropdown extends React.Component{
    constructor(props) {
        super(props);
        this.state = {options:[]};
    }
    render() {
        return (
            <Query query={gql`${getUsers}`} >
                {(result) => {
                    if (result.loading) return "Loading...";
                    if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                        message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    const {data} = result;
                    
                    return <Typeahead ref="typeahead"
                                      options={data.getUsers.filter(
                                          user => !this.global.roles.includes("SALES")
                                              || this.global.roles.includes("SALESADMIN")
                                                        && user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                              || this.global.roles.includes("SALESMANAGER")
                                                  && (user.roles.filter(({role}) => role === "SALESADMIN").length > 0
                                                  || user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                                  && user.organizations.filter(({businessPartnerId}) => businessPartnerId === this.global.salesOrganization).length > 0)
                                              || this.global.roles.includes("SALESAGENT")
                                                  && user.roles.filter(({role}) => role === "SALESMANAGER" || role === "SALESAGENT").length > 0
                                                  && user.organizations.filter(({businessPartnerId}) => businessPartnerId === this.global.salesOrganization).length > 0)}
                                      labelKey="username"
                                      selectHintOnEnter={true}
                                      placeholder={this.props.placeholder}
                                      filterBy = {['username']}
                                      clearButton
                                      onChange={(event) => {
                                          this.props.onChange(event[0] ? event[0].username : null);
                                      }}
                                      selected={this.props.value? [{username: this.props.value}] : []}
                    />
                }
                }
            </Query>
        );
    }
}

class TaskDueDate extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <SingleDatePicker
                date={this.props.value === "" ? null : moment(this.props.value)} // momentPropTypes.momentObj or null
                numberOfMonths={1}
                onDateChange={date => {
                    this.props.onChange(date ? date.format("YYYY-MM-DD") : null);
                }}
                displayFormat="DD/MM/YY"
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                id="task-due-date" // PropTypes.string.isRequired,
                />
        );
    }
}


const uischema = {
    id : {
        "ui:widget" : "hidden"
    },
    name : {
        "ui:widget": (props) => {
            return (
                <div>
                <span className="task-status icon ion-android-checkbox-blank" onClick={
                    () => {
                        props.formContext.submit({formData : {...props.formContext.formdata, status:"Closed"}});
                    }
                }/>
                       <Textarea className={props.schema.classes}
                       value={props.value}    required={props.required}
                       placeholder={props.schema.placeholder}  autoFocus ={true}
                       onChange={(event) => {
                           props.onChange(event.target.value)
                       }}/>
                </div>
            );
        },
        "classNames":'task-title',
        "ui:autofocus": true
    },
    process: {
        "ui:placeholder": 'Select Process',
        "ui:enumDisabled": ['Select Process'],
    },
    assignee : {
        "ui:widget": (props) => {
            return (
                <UserDropdown {...props}/>
            );
        },
        "ui:placeholder": "Select assignee",
    },
    customer : {
        "ui:widget": (props) => {
            return <CustomerSearch {...props} />
        },
        "ui:placeholder": "Select Customer",
    },
    dueDate : {
        "ui:widget" : (props)=>{
                return <TaskDueDate {...props}/>
        },
        "ui:placeholder": "Select Date",
        "classNames":"duedate"
      
    },
    description : {
        "ui:widget" : (props)=>{
            return <Textarea value={props.value ? props.value : ""}  placeholder="Task Description"  onChange={(event) => {
                props.onChange(event.target.value)
            }}/>
        },
        "classNames":'description',
    },
    
    "ui:order": ["id", "name", "process", "assignee", "customer", "status", "dueDate", "priority", "description", ]

}
export default uischema;