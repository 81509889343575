import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row, FormGroup} from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "./i18n";
import { translate } from 'react-i18next';
//import showResults from './../../../components/showResults';
import {compose, graphql} from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "../../../utils/Client";

const validate = values => {
    const errors = {}
    const requiredFields = ['username', 'password']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    })
    if (values.username && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
}


class Login extends Component {

    handleFormSubmit(values, event, props) {
        return axios
            
            .post(`${CORE_URL}/login`, {
                userName: values.username,
                password: values.password
            })

            .then(response => {

                if(!response.data.token) {

                    if (response.data.code) {
                        console.log('there was an error sending the query', response.data.code);
                    }

                    if(response.data.message && response.data.message.includes('expired')) {
                        this.props.history.push('/change-expired-password');
                    }

                    throw new SubmissionError({ _error: response.data.message});
                }

                else {
                    var expDate = new Date();
                    expDate.setTime(response.data.expirationDate);
                    document.cookie = "token=true;expires="+expDate.toUTCString()+";path=/";
                    localStorage.token = response.data.token;
                    props.history.push('/');
                }
            })

            .catch(error => {

                console.log('there was an error sending the query', error);

                if (error.response) {

                    if(error.response.data.message && error.response.data.message.includes('expired')){
                        this.props.history.push('/change-expired-password');
                    }

                    throw new SubmissionError({ _error: error.response.data.message});
                }
                else {
                    const { errors : { _error }} = error;
                    throw new SubmissionError({ _error: _error});
                }    
            });
    }

    render() {

        const {handleSubmit, onSubmit, pristine, reset, invalid, submitting, error} = this.props;

        const errorMessage = error ? <p className="text-danger login-err">{ error }</p> :
            <p className="signin-text-muted">{ i18n.t('login.subtitle') }</p>
        return (
            <div className="app flex-row align-items-center">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col md="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <CardGroup>
                                <Card className={DYCE ? "login-panel": "p-4 login-panel"} >
                                    <CardBody className='credentials-card'>
                                        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                            <div className='login-logo '></div>
                                            {errorMessage}
                                            <label htmlFor="username"
                                                   className='label-name'>{ i18n.t('login.username') }</label>
                                            <Field inputGroup={{className: "mb-3"}}
                                                   placeholder={ i18n.t('login.userEmail') }
                                                   //inputAddonGroup={{className: 'icon-user'}}
                                                   name="username"
                                                   component={renderInput} type="email" />
                                            <label htmlFor="password"
                                                   className='label-name'>{ i18n.t('login.password') }</label>
                                            <Field inputGroup={{className: "mb-4"}}
                                                   placeholder={ i18n.t('login.password') }
                                                   //inputAddonGroup={{className: 'icon-lock'}}
                                                   name="password"
                                                   component={renderInput} type="password"/>

                                            <Row className='margin-b-30'>
                                                {/*<Col>*/}
                                                    {/*<Field name="autologin"*/}
                                                           {/*id="autologin"*/}
                                                           {/*component="input"*/}
                                                           {/*className='css-checkbox'*/}
                                                           {/*type="checkbox"/>*/}

                                                    {/*<label htmlFor="autologin"*/}
                                                           {/*className="css-label"><i className="icon ion-android-checkbox"></i>{ i18n.t('login.autoLogin') }</label>*/}
                                                {/*</Col>*/}
                                                <Col xs="12" className="text-right">
                                                    <Button color="link" onClick={() => this.props.history.push('/reset-password')}
                                                            className="px-0">{ i18n.t('login.forgottenPasswordLink') }</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="6">
                                                    <Button color="primary"
                                                            className="px-4 sign-in"
                                                            disabled={invalid || submitting}>{ i18n.t('login.buttonLabel') }</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                                {!DYCE && <Card>
                                    <CardBody className="text-center side-image">
                                    </CardBody>
                                </Card>}
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(Login);
