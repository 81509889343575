import React, { Component } from 'reactn';
import {
    Col,
    InputGroup,
    Input,
    Nav,
    NavItem,
    NavbarToggler,
    NavbarBrand,
    NavLink,
    Badge,
    Row,
} from 'reactstrap';
import Search from '../../components/Search';
import i18n from "../../views/Pages/Login/i18n";
import HeaderDropdown from './HeaderDropdown';
import {getBusinessPartnerShortQuery} from "../../queries/Queries"


const templateFromDatabase = `<div className="user-row">
    <div className="user-col"><i className="icon ion-person"></i></div>
    <div className="user-col"><Highlighter search="'ni'"><%= name %></Highlighter></div>
    <div className="user-col"><%= email %></div>
    <div className="user-col"><%= phone %></div>
</div>`;



const filterByFileds = ["name", "phone", "email", "billingAccountNumber", "meterpoint"];

const searchWhere = "lower(name) like '%<%= query %>%'" +
    " or mdmEik like '%<%= query %>%'" +
    " or mdmPersonalno like '%<%= query %>%'" +
    " or id in (select bp.id from BusinessPartner bp join bp.cRMBpartnerContactList u where u.user.mdmIsowner='Y' and (u.user.email like '%<%= query %>%' or u.user.phone like '%<%= query %>%'))" +
    " or id in (select bp.id from BusinessPartner bp join bp.bILLBillingAccountList u where lower(u.billingAccountNumber) like '%<%= query %>%')" +
    " or id in (select bp.id from BusinessPartner bp join bp.mDMBPEstateList u where u.id in (select e.id from MDM_BPEstate e join e.mDMEstate es join es.mDMMeterPointList v where lower(v.name) like '%<%= query %>%'))";

const searchOrderBy = "name";


const jsonataTemplate = '[$.{"name" : name,' +
    ' "id" : id,' +
    ' "phone" : (cRMBpartnerContactList.user[isMdmIsowner=true])[0].phone & "",' +
    ' "email" : (cRMBpartnerContactList.user[isMdmIsowner=true])[0].email & "",' +
    ' "billingAccountNumber": bILLBillingAccountList.billingAccountNumber,' +
    ' "meterpoint": mDMBPEstateList.mDMEstate.mDMMeterPointList.name}]';

class Header extends Component {

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    asideToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    render() {
        return (
            <header className="app-header navbar">
                {/* <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler> */}
                
                {/* <InputGroup className='col-6 header-search-input'>
                    <i className='search-icon'></i><Input className='col-9' placeholder={ i18n.t('header.manageUsers') } />
                </InputGroup> */}

                {this.global.permissions.includes('Customer.Search') && <Search query={getBusinessPartnerShortQuery}
                    template={templateFromDatabase}
                    filterByFields={filterByFileds} jsonata={jsonataTemplate} where={searchWhere} orderBy={searchOrderBy} /> }

                <Nav className="ml-auto user-menu" navbar>
                    {/* <NavItem className="d-md-down-none">
                        <NavLink href="#"><i className="icon-bell"></i><Badge pill color="danger">5</Badge></NavLink>
                    </NavItem> */}
                    {/* <NavItem className="d-md-down-none">
                        <NavLink href="#"><i className="icon-list"></i></NavLink>
                    </NavItem> */}
                    {/* <NavItem className="d-md-down-none">
                        <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
                    </NavItem> */}
                    <HeaderDropdown currentUser={this.props.currentUser}/>
                </Nav>
                {/* <NavbarToggler className="d-md-down-none" onClick={this.asideToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler> */}

            </header>
        );
    }
}

export default Header;
