import React, { Component } from 'reactn';
import {Label, Input, Row, Col, FormGroup} from 'reactstrap';
import axios from "../../utils/Client";

class SelectTariffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Tariffs: [],
        isLoading: true
    };
  }

    componentDidMount(){
      const contractDurationinMonths = parseInt(this.props.quote.contractDuration);
      console.log(this.props.quote);
      axios.get(TARIFFS_URL + '/tariff/h2/broker/DYCE/aq/' + (this.props.quote.annualgasIndustry ? this.props.quote.annualgasIndustry : this.props.quote.annualgas) + '/ldz/' + this.props.quote.supplyAddress.ldz + '/term/' + contractDurationinMonths + " Month")
          .then(response => {
              const Tariffs = [];
              response.data.forEach(
                price => {
                    Tariffs.push({
                        title: 'GAS Fixed ' + contractDurationinMonths + ' Months',
                        value: JSON.stringify(price),
                        active: true,
                        rates: [
                            {
                                name: 'Gas',
                                unitRate: price.unitRate + ' p/kWh',
                                sc: price.sc + ' p/day'
                            }
                        ]
                    })
                }
              )
              this.setState({Tariffs : Tariffs, isLoading: false});
          })
          .catch(error =>  {
              console.log(error);
              this.setState({isLoading: false});
          })
          ;
  }

  render() {
    return (
      <div className={"tariff-boxes " + this.props.className} >
        {!this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") && (this.props.quote.annualgasIndustry ? this.props.quote.annualgasIndustry : this.props.quote.annualgas) <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) && this.state.Tariffs.map((e) => (
          <div key={e.value} className="tariff-box">
            <Input type="radio" onChange={(ev) => {
                        this.props.setTariff(JSON.parse(ev.target.value))}}
                   name={this.props.name ? this.props.name : 'tariffName'} required
                   disabled={!e.active} id={e.value} value={e.value}
                   checked={this.props.value.id === JSON.parse(e.value).id}/>
            <Label htmlFor={e.value} style={{cursor: 'pointer'}}>
              <Row>
                <Col className="col-12 header">
                  <i className="icon ion-android-radio-button-off"/>
                  <i className="icon ion-android-radio-button-on"/>
                  <h2>{e.title}</h2>
                </Col>
                {e.rates.map((rate) => {
                  return (
                    <Col key={rate.name} className="col-xs-12">
                      <h4>{rate.name}</h4>
                      <table className="rates">
                        <tbody>
                          <tr>
                            <td>Unit Rate</td>
                            <td>{rate.unitRate}</td>
                          </tr>
                          <tr>
                            <td>Standing Charge</td>
                            <td>{rate.sc}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  );
                })}
              </Row>
            </Label>
          </div>
        ))}
          {((this.props.quote.annualgasIndustry ? this.props.quote.annualgasIndustry : this.props.quote.annualgas) > parseInt(AQ_GAS_BESPOKE_THRESHOLD) || this.global.permissions.includes("Quotes.AlwaysUseBespokePrice"))
          && <Row>
              <Col className='col-6'>
                  <FormGroup>
                      <Label for="unitRate">Unit Rate (p/kWh)</Label>
                      <Input type="number" name="unitRate" id="unitRate" step="0.001" min="0.001" onChange={(ev) => {
                          this.props.setTariff({...this.props.value, name: "Bespoke price", tariffCode: [12,24,36].includes(parseInt(this.props.quote.contractDuration)) ? this.props.quote.contractDuration+'MTH FIXED GAS 150118' : 'FIXED END DATE', unitRate: parseFloat(ev.target.value), bespokePrice: true})}}
                             value={this.props.value.unitRate} placeholder="Unit Rate" required/>
                  </FormGroup>
                  <FormGroup>
                      <Label for="sc">Standing Charge (p/day)</Label>
                      <Input type="number" name="sc" id="sc" step="0.001" min="0" onChange={(ev) => {
                          this.props.setTariff({...this.props.value, name: "Bespoke price", tariffCode: [12,24,36].includes(parseInt(this.props.quote.contractDuration)) ? this.props.quote.contractDuration+'MTH FIXED GAS 150118': 'FIXED END DATE', sc: parseFloat(ev.target.value), bespokePrice: true})}}
                             value={this.props.value.sc} placeholder="Standing Charge" required/>
                  </FormGroup>
              </Col>
          </Row>
          }

          {!this.global.permissions.includes("Quotes.AlwaysUseBespokePrice") && this.props.quote.annualgas <= parseInt(AQ_GAS_BESPOKE_THRESHOLD) && !this.state.isLoading && (!this.state.Tariffs || this.state.Tariffs.length === 0)
          && <Row>
              <Col className='col-6'>
                  <div className="alert alert-danger alert-dismissible fade show">
                      <button type="button" className="close" data-dismiss="alert">×</button>
                      <h4 className="alert-heading">Missing Tariff!</h4> We could not find tariff for the customer. The Quote cannot be completed.
                  </div>
              </Col>
          </Row>}


      </div>
    );
  }
}

export default SelectTariffs;