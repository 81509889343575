import React, {useEffect, useState} from "react";
import Input from 'react-phone-number-input/input';

function PhoneField(props) {
    const {
        options,
        required,
        value,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        type,
        id
    } = props;

    const [filedState, setFiledState] = useState();
    const max = props.schema.maxLength;

    useEffect(() => {
        if (filedState && filedState !== props.value && filedState.length <= props.schema.maxLength) {
            props.onChange(filedState);
        }
    });

    return (
        <div className="two-columns-group" id={id}>
            <div className="form-group">
                <label htmlFor={props.label}
                       className="col-md-12 col-form-label">{props.schema.title}{required ? '*' : ''}</label>
                <div className="col-md-12">
                    <Input className="form-control"
                           maxlength={max.toString()}
                           country={props.schema.country}
                           international
                           withCountryCallingCode
                           onChange={setFiledState}
                           value={filedState}
                    />
                </div>
            </div>
        </div>
    );
}

export default PhoneField;