import React, {Component} from 'react';

import {graphql} from "react-apollo";
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {dateFormatter, periodFormatter, priceFormatter} from "../../utils/Formatters";
import QueryDataGrid from "../QueryDataGrid";
import i18n from '../../views/Pages/Login/i18n';
import {withRouter} from 'react-router';
import Loading from '../Loading';
import NotesAndAttachments from "../../components/NotesAndAttachments"
import axios from "../../utils/Client";
import fileDownload from 'js-file-download';
import {getBusinessPartnerQuery, getInvoiceQuery} from "../../queries/Queries"

class BillsDetail extends Component {

    downloadInvoice() {
        console.log('download');
    }

    render() {
        var invoiceItems = [];
        return (!this.props.getInvoice.loading && this.props.getInvoice.invoice) ? (
            <div className="invoice-details">
                <Row>
                    <Col sm="12" className="header">
                        <Button onClick={() => this.props.history.push('/bp/'+this.props.match.params.id)} className="btn btn-default"><i className="icon ion-arrow-left-b"></i>{i18n.t('businessPartner.invoice')}:&nbsp;&nbsp;{this.props.getInvoice.invoice.documentNo}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <table className="invoice-stats">
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Invoicedate')}
                                                </td>
                                                <td>
                                                    {dateFormatter(this.props.getInvoice.invoice.invoiceDate,null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Duedate')}
                                                </td>
                                                <td>
                                                    {dateFormatter(this.props.getInvoice.invoice.fINPaymentScheduleList[0].dueDate)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Period')}
                                                </td>
                                                <td>
                                                    {periodFormatter(this.props.getInvoice.invoice.billPeriod)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Payment')}
                                                </td>
                                                <td className={(this.props.getInvoice.invoice.isPaymentComplete) ? 'paid' : 'unpaid'}>
                                                    {this.props.getInvoice.invoice.isPaymentComplete ? 'Paid' : 'Unpaid'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.outstanding')}
                                                </td>
                                                <td>
                                                    {priceFormatter(this.props.getInvoice.invoice.outstandingAmount, this.props.getInvoice.invoice) }
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col>
                                        <Button color='info' className="pull-right" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            axios.get(`${CORE_URL}/invoice/${this.props.match.params.billid}`, {
                                                headers: {'Authorization': "Bearer " + localStorage.token},
                                                responseType: 'blob'
                                            }).then((response) => {
                                                fileDownload(response.data, this.props.getInvoice.invoice.documentNo+".pdf");
                                            })
                                        }} ><i className="icon ion-android-download"></i>{i18n.t('businessPartner.Download')}</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <QueryDataGrid className="invoice-table" dataPath={'invoice.invoiceLineList'} keyField={'id'} query={`
                                            query invoiceQuery {
                                            invoice(startRow: 0, offset: 100, where: "id = '${this.props.match.params.billid}'", orderBy: "id") {
                                                        id
                                                        invoiceLineList(startRow: 0, offset: 100, where: "id is not null", orderBy: "id") {
                                                            id
                                                            product{
                                                                name
                                                                mdmPurpose
                                                            }
                                                            invoicedQuantity
                                                            uOM{
                                                                name
                                                            }
                                                            unitPrice
                                                            lineNetAmount
                                                            invoice{
                                                                currency{
                                                                    symbol
                                                                    iSOCode
                                                                }
                                                            }
                                                        }
                                                        noteList{
                                                            id
                                                            note
                                                            creationDate
                                                            updated
                                                            createdBy{
                                                                name
                                                            }
                                                            updatedBy{
                                                                name
                                                            }
                                                        }
                                                        attachmentList{
                                                            id
                                                            name
                                                            creationDate
                                                            updated
                                                            text
                                                            createdBy{
                                                                name
                                                            }
                                                            updatedBy{
                                                                name
                                                            }
                                                        }
                                            }
                                        }

                                        ` }
                                        columns={[{
                                            dataField: 'product.mdmPurpose',
                                            text: 'Type',
                                            //sort: true
                                        }, {
                                            dataField: 'product.name',
                                            text: 'Product Name',
                                            sort: true
                                        }, {
                                            dataField: 'invoicedQuantity',
                                            text: 'Quantity',
                                            sort: true
                                        }, {
                                            dataField: 'uOM.name',
                                            text: 'Unit',
                                            sort: true
                                        }, {
                                            dataField: 'unitPrice',
                                            text: 'Price',
                                            sort: true,
                                            align: "right",
                                            headerAlign: "right",
                                            formatter: priceFormatter
                                        }, {
                                            dataField: 'lineNetAmount',
                                            text: 'Total',
                                            sort: true,
                                            align: "right",
                                            headerAlign: "right",
                                            formatter: priceFormatter
                                        }]}

                                    />
                                </Row>
                                <div className="subtotals" noGutter>
                                    <Row>
                                        <Col sm="6">
                                            Subtotal:</Col>
                                        <Col sm="6">
                                            {priceFormatter(this.props.getInvoice.invoice.invoiceTaxList[0].taxableAmount, this.props.getInvoice.invoice)}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.discount')}:
                                            </Col>
                                        <Col className="">

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.vat')}T:</Col>
                                        <Col sm="6">
                                            {priceFormatter(this.props.getInvoice.invoice.invoiceTaxList[0].taxAmount, this.props.getInvoice.invoice)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.total')}: </Col>
                                        <Col sm="6">
                                            {priceFormatter(this.props.getInvoice.invoice.grandTotalAmount, this.props.getInvoice.invoice)}
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                    <Row>
                        <NotesAndAttachments noteList={this.props.getInvoice.invoice.noteList} attachmentList={this.props.getInvoice.invoice.attachmentList} recordId={this.props.match.params.billid} tableName={"Invoice"}
                                             refetchQueries={[{query: getBusinessPartnerQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.id + "'", orderBy: "name" }},
                                             {query:getInvoiceQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.billid + "'", orderBy: "id" }}]}
                                             />
                    </Row>
            </div>) : (<Loading/>);
    }
}




export default graphql(getInvoiceQuery, {
    name: 'getInvoice',
    options: props => ({
        variables: { where: "id = '" + props.match.params.billid + "'", orderBy: "id" }
    }),
})(withRouter(BillsDetail));
